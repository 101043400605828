<template>
  <div class="bg-white px-6 py-6 space-y-5 rounded-lg shadow container mx-auto">
    <h2 class="text-3xl font-bold text-left">
      {{ isEdit ? $t("editCustomer") : $t("addNewCustomer") }}
    </h2>
    <TransitionRoot
      :show="showForm"
      enter="transition ease-in-out duration-300 transform"
      enter-from="-translate-x-full"
      enter-to="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-x-0"
      leave-to="-translate-x-full"
    >
      <Form
        @submit="onSubmitCustomer"
        v-slot="{ meta }"
        :validation-schema="schema"
        class="flex flex-col w-full space-y-5 items-start"
      >
        <div class="grid grid-cols-2 gap-6 text-left w-full">
          <div class="flex items-start flex-col w-full space-y-4">
            <l-input-field
              :label="$t('firstName')"
              name="firstName"
              type="text"
              :value="firstName"
            >
            </l-input-field>
            <l-input-field
              :label="$t('lastName')"
              name="lastName"
              type="text"
              :value="lastName"
            >
            </l-input-field>
            <l-input-field
              :label="$t('emailId')"
              name="emailId"
              type="email"
              :value="emailId"
            >
            </l-input-field>
            <l-input-field
              :label="$t('mobile')"
              name="mobile"
              type="number"
              :value="mobile"
            >
            </l-input-field>
            <l-input-field
              :label="$t('street')"
              name="street"
              type="text"
              :value="street"
            >
            </l-input-field>
            <l-input-field
              :label="$t('houseNo')"
              name="houseNo"
              type="text"
              :value="houseNo"
            >
            </l-input-field>
          </div>
          <div class="flex flex-col w-full space-y-4">
            <l-input-field
              :label="$t('city')"
              name="city"
              type="text"
              :value="city"
            >
            </l-input-field>
            <l-input-field
              :label="$t('postalCode')"
              name="postalCode"
              type="number"
              :value="postalCode"
            >
            </l-input-field>
            <l-input-field
              :label="$t('country')"
              name="country"
              type="text"
              :value="country"
            >
            </l-input-field>
            <l-input-field
              :label="$t('stNr')"
              name="stNr"
              type="text"
              :value="stNr"
            >
            </l-input-field>
            <l-input-field
              :label="$t('ustIdr')"
              name="ustIdr"
              type="text"
              :value="ustIdr"
            >
            </l-input-field>
            <label class="block w-full">
              <span class="text-gray-700">{{ $tc("remark", 2) }}</span>
              <Field
                as="textarea"
                name="remarks"
                v-model="remarks"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                rows="3"
              ></Field>
              <ErrorMessage class="text-red-500" name="remarks" />
            </label>
          </div>
        </div>
        <div
          class="
            w-full
            pt-8
            border-b border-brand border-opacity-50 border-dashed
          "
        ></div>
        <div class="w-full pt-1"></div>
        <div class="grid grid-cols-2 gap-6 text-left w-full pb-4">
          <l-input-field
            :label="$t('password')"
            name="password"
            type="password"
            :value="password"
          >
          </l-input-field>
          <l-input-field
            :label="$t('passwordConfirmation')"
            name="passwordConfirmation"
            type="password"
            :value="password"
          >
          </l-input-field>
        </div>
        <div class="flex w-full justify-center items-center space-x-4">
          <button
            @click="$router.go(-1)"
            class="
              bg-red-500
              cursor-pointer
              rounded
              px-4
              py-2
              text-white
              font-bold
            "
          >
            {{ $t("cancel") }}
          </button>
          <button
            type="submit"
            class="bg-brand rounded px-4 py-2 text-white font-bold"
            :class="{
              'opacity-50 cursor-not-allowed': !meta.valid,
              'opacity-100 cursor-pointer': meta.valid,
            }"
          >
            {{ isEdit ? $t("update") : $t("submit") }}
          </button>
        </div>
      </Form>
    </TransitionRoot>
  </div>
</template>

<script>
import { default as toast } from "@/utils/toastUtils";
import { useForm, Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { TransitionRoot } from "@headlessui/vue";
import LInputField from "@/components/custom/LInputField";
import { useStore } from "vuex";

export default {
  name: "AddEditCustomer",
  components: { LInputField, Field, Form, ErrorMessage, TransitionRoot },
  setup() {
    const store = useStore();
    // Define a validation schema
    const schema = yup.object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      emailId: yup
        .string()
        .email()
        .required()
        .test(
          "checkEmailUnique",
          "This email is already registered.",
          (value) => {
            // eslint-disable-next-line
            let rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
            if (rEmail.test(value)) {
              return store.dispatch("checkEmail", { email: value });
            } else {
              return Promise.resolve(false);
            }
          }
        ),
      mobile: yup.number().required(),
      street: yup.string().required(),
      houseNo: yup.string().required(),
      city: yup.string().required(),
      country: yup.string().required(),
      postalCode: yup.number().required().min(4),
      stNr: yup.string().required(),
      ustIdr: yup.string().required(),
      password: yup
        .string()
        .min(8)
        .required()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      passwordConfirmation: yup
        .string()
        .required()
        .oneOf([yup.ref("password")], "Passwords do not match"),
    });

    const { meta, errors } = useForm({
      validationSchema: schema,
    });

    return {
      schema,
      meta,
    };
  },
  props: {
    customerId: {
      default: "",
      type: [String],
    },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      emailId: "",
      mobile: "",
      street: "",
      houseNo: "",
      city: "",
      country: "",
      postalCode: "",
      stNr: "",
      ustIdr: "",
      password: "",
      passwordConfirmation: "",
      remarks: "",
      showForm: false,
    };
  },
  computed: {
    isEdit() {
      return this.customerId !== "";
    },
  },
  methods: {
    onSubmitCustomer(values) {
      let data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.emailId,
        mobile: values.mobile,
        password: values.password,
        street: values.street,
        house_no: values.houseNo,
        city: values.city,
        st_nr: values.stNr,
        ust_ldnr: values.ustIdr,
        postal_code: values.postalCode,
        country: values.country,
        remarks: values.remarks,
      };
      if (this.isEdit) {
        data["supplier_id"] = this.supplierId;
        this.$store
          .dispatch("updateSupplier", data)
          .then((data) => {
            toast.success(data.message);
            this.$router.push({ name: "suppliers" });
          })
          .catch((err) => {
            toast.error(err);
          });
      } else {
        this.$store
          .dispatch("addNewCustomer", data)
          .then((data) => {
            toast.success(data.message);
            this.$router.push({ name: "customers" });
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    },
    checkSupplier() {
      if (this.customerId) {
        this.$store
          .dispatch("fetchSupplier", { supplier_id: this.supplierId })
          .then((response) => {
            let data = response.supplier;
            console.log(data);
            this.fullName = data.full_name || "";
            this.company = data.company || "";
            this.address = data.address || "";
            this.emailId = data.email || "";
            this.mobile = data.mobile || "";
            this.stNr = data.stnr || "";
            this.telephone = data.telephone || "";
            this.status = data.status.toString() || "";
            this.ustIdr = data.ustidnr || "";
            this.city = data.city || "";
            this.country = data.country || "";
            this.postalCode = data.postalcode || "";
            this.remarks = data.remarks || "";

            this.showForm = true;
          });
      } else {
        this.showForm = true;
      }
    },
  },
  created() {
    this.checkSupplier();
  },
};
</script>

<style scoped></style>
